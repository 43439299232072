<template>
  <b-container class="mt-4">
    <b-row>
      <b-col cols="11">
        <h4>SFTP User Details</h4>
      </b-col>
      <b-col>
        <b-button
          :disabled="loading"
          variant="primary"
          :to="`/sftp/users/${server}/${username}/edit`"
          class="float-right">
          <feather type="edit"></feather>
          Edit
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="loading">
      <b-col cols="12">
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
    <b-row v-else class="mt-3">
      <b-col cols="12">
        <b-card>
          <p class="card-text"><b>Username:</b> {{ username }}</p>
          <p class="card-text"><b>First Name:</b> {{ additional_info.firstname }}</p>
          <p class="card-text"><b>Last Name:</b> {{ additional_info.lastname }}</p>
          <div class="card-text" v-if="server === 'external'">
            <p><b>Company:</b> {{ additional_info.company }}</p>
          </div>
          <p class="card-text"><b>Active:</b> <UserStatusBadge class="ml-1" :status="user.status"/></p>
          <p class="card-text"><b>Has Password:</b> <UserStatusBadge class="ml-1" :status="user.has_password"/></p>
          <div v-if="server === 'external'">
            <p class="card-text"><b>Home Directory:</b> {{ user.filesystem.gcsconfig.key_prefix || '/' }}</p>
          </div>
          <b-row class="card-text" v-if="server === 'internal'">
            <b-col cols="1">
              <b>Groups</b>
            </b-col>
            <b-col cols="6">
              <multiselect :multiple="true" disabled v-model="groups" :options="options"></multiselect>
            </b-col>
          </b-row>
          <b-row class="card-text mt-2" v-if="server === 'internal'">
            <b-col cols="1">
              <b>Email</b>
            </b-col>
            <b-col cols="6">
              <b-form-input disabled v-model="user.email"/>
            </b-col>
          </b-row>
          <b-row class="card-text mt-2" v-else>
            <b-col cols="1">
              <b>Emails</b>
            </b-col>
            <b-col cols="6" class="ml-0">
              <b-row v-for="(_, idx) in additional_info.emails" :key="idx" class="mb-2">
                <b-col cols="11">
                  <b-form-input disabled v-model="additional_info.emails[idx]"/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div v-if="publicKeys && publicKeys.length > 0">
            <p class="card-text"><b>Public Keys:</b></p>
            <div class="ml-2 mb-3">
              <b-row v-for="(publicKey, idx) in publicKeys" :key="idx">
                <b-col cols="7">
                  <b-textarea disabled rows="4" :value="publicKey"/>
                </b-col>
              </b-row>
            </div>
          </div>
          <p class="card-text"><b>Permissions</b></p>
          <div class="ml-2">
            <b-row v-for="(permission, idx) in permissions" :key="idx" class="mt-1">
              <b-col cols="3">
                <b-input disabled v-model="permission.path"/>
              </b-col>
              <b-col cols="2">
                <b-form-select disabled v-model="permission.role" :options="roles"/>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { isNullOrEmpty } from '@/helpers';
import Multiselect from 'vue-multiselect';
import UserStatusBadge from '@/components/SFTP/Users/UserStatusBadge.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'SFTPUserDetails',
  components: {
    Multiselect,
    UserStatusBadge,
  },
  data() {
    return {
      loading: false,
      server: null,
      username: null,
      user: null,
      additional_info: {
        firstname: null,
        lastname: null,
        company: null,
        emails: [],
      },
      permissions: [],
      publicKeys: [],
      groups: [],
      options: [],
      roles: [
        { value: 'admin', text: 'Admin' },
        { value: 'write', text: 'Write' },
        { value: 'read', text: 'Read' },
        { value: 'deny', text: 'Deny' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      canAccessSftpUsersAndGroups: 'User/canAccessSftpUsersAndGroups',
    }),
  },
  async beforeMount() {
    if (!this.canAccessSftpUsersAndGroups) {
      this.$router.push('/not-found');
      return;
    }

    this.server = this.$route.params.server;
    this.username = this.$route.params.username;
    try {
      this.loading = true;
      await this.getGroups();
      await this.getUser();
    } catch (err) {
      this.$noty.error(`Failed to fetch user details: ${err.message}`);
    }
  },
  methods: {
    async getGroups() {
      const { data } = await this.$store.dispatch('Sftp/Groups/getGroups', { server: this.server });
      data.forEach(group => this.options.push(group.name));
    },
    async getUser() {
      try {
        this.loading = true;

        const { data } = await this.$store.dispatch('Sftp/User/getUser', {
          server: this.server,
          username: this.username,
        });

        this.user = data;
        this.publicKeys = isNullOrEmpty(data.public_keys) ? [] : data.public_keys;
        this.permissions = Object.entries(data.permissions).map(([ path, role ]) => ({ path, role }));
        this.parseAdditionalInfo();

        if (data.groups) {
          this.groups = data.groups.map(group => group.name);
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    parseAdditionalInfo() {
      if (!isNullOrEmpty(this.user.additional_info)) {
        const info = JSON.parse(this.user.additional_info);

        if (!isNullOrEmpty(info.firstname)) {
          this.additional_info.firstname = info.firstname;
        }

        if (!isNullOrEmpty(info.lastname)) {
          this.additional_info.lastname = info.lastname;
        }

        if (!isNullOrEmpty(info.company)) {
          this.additional_info.company = info.company;
        }

        if (!isNullOrEmpty(info.emails)) {
          this.additional_info.emails = info.emails;
        }
      }
    },
  },
};
</script>
